

div.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: center;
    height: 100%;
}
.screen_share_example{
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}
div.column > .btn{
    padding: 10px;
}
div.pass > .icn{
    fill: #46be8a;
    align-self: center;
}

div.column .bounds{
    display: none;
    height: 60%;
    width: 100%;
    align-self: baseline;
    padding-left: 15px;
}

