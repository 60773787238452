.img_example {
    max-width: 100%;
    max-height: 100%;
    padding-right: 30px;
}

.permissions-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.flex-div{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;}

.perm-title {
  margin-bottom: 30px;
}

.note + .perm {
  margin-top: 0;
}
.perm {
  padding: 0;
  list-style-type: none;
}

.perm > li, .perm section {
   padding: 20px 0 20px 50px;
  position: relative;
}
.perm > li + li, .perm section + li {
  border-top: 1px solid #e4eaec;
}

.perm__status {
  border: 1px solid #e4eaec;
  border-radius: 50%;
  content: '';
  height: 30px;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transition: all 0.125s 0.125s ease;
  width: 30px;
}

.perm__status:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  content: '';
  height: 17px;
  margin-top: -1px;
  opacity: 0;
  position: absolute;
  transform: scale(0.5) translateX(-50%) translateY(-50%);
  transition: all 0.125s 0.25s ease;
  width: 8px;
}

.perm__status.completed {
  background: #46be8a;
  border-color: #46be8a;
}

.perm__status.completed:after {
  opacity: 1;
  transform: translateX(75%) translateY(25%);
}

.perm__status.in-progress {
  background-color: #e4eaec;
}
