.id-right-pane {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.hide-me {
    display: none;
}

.capture-photo {
    width: fit-content;
    margin: auto;
    margin-top: 10px;
}

 .heading-icon {
    margin: auto;
    padding-bottom: 10px;
  }

.action--tray {
    display: inline-flex;
}

.face-check-video {
    margin: auto;
    min-height: 0;
    max-width: 100%;
    flex: 1;
}

.face-check-canvas {
    max-width: 100%;
}

.webcam {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    flex: auto;
    min-width: 0;
    min-height: 0;
}

.webcam__frame {
    height: 70%;
    left: 53%;
    top: 30%;
    transform: translateX(-55%) translateY(-35%);
    position: absolute;
    max-width: 100%;
    z-index: 1;
}

.webcam__frame--landscape {
    max-height: 80%;
    width: 90%;
    position: absolute;
    left: 5%;
    bottom: 15%;
  }

.id-icon {
    height: 48.7px;
    width: 72.5px;
    margin: auto;
    margin-bottom: 10px;
}

.headshot-icon {
    height: 48.7px;
    margin: auto;
    margin-bottom: 10px;
}