.icn-microphone {
  max-height: 150px;
  max-width: 150px;
  fill: #0074cc
}

.flex-container__audio-check, .mic-check {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-weight: 300;
  margin-bottom: .5em;
}

.select__audio-check {
  display: inline-block;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
}

.select__audio-check > select {
  background: #fff;
  border: 1px solid #0074cc;
  border-radius: 3px;
  color: #526069;
  font-size: inherit;
  height: 100%;
  line-height: 1.5;
  padding: 6px 27px 6px 10px;
  width: 100%;
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__audio-check::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: 4px solid #6c7884;
  border-bottom-width: 0;
  border-right-color: transparent;
  border-left-color: transparent;
  content: '';
  display: inline-block;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  width: 0;
}

.img-banner {
  width: 100%;
}

.img-banner td {
  text-align: center;
}