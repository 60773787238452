div.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
    padding-left: 15px;

}

div.column.recording .btn {
    min-width: 200px;
}

.column.room-scan {
    height: 75%;
}

figure.webcam{
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 350px;
    min-height: 300px;
}

figure.webcam  .btn{
    margin: 10px auto 0 auto;
    padding-left: 15px;
    height: auto;
    min-width: 200px;
    width: fit-content;
}

.icon-text{
    margin-top: -50px;
    margin-bottom: 30px;
}
.apps-list{
    padding: 0px;
}

#room-scan-video {
    border: 1px solid #ccc;
    width: 100%;
}

.room-scan--figure {
    position: relative;
    border-radius: 3px;
}

.play-btn {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: #000000;
    transition: all .25s ease;
    z-index: 1;
    cursor: pointer;
}

.play-btn > .icn {
    position: relative;
    height: 65px;
    width: 65px;
    fill: #fff;
    top: 50%;
    left: 40%;
    transform: translateX(-50%) translateY(-50%);
}

.play-btn:hover {
    background: #404040;
}

.room-scan--controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}

.room-scan--controls input {
    align-self: flex-start;
    margin-right: 15px;
}

.room-scan--checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    text-align: left;
}