.tabs {
    justify-content: space-between;
    width: 100%;
}
.tab {
    width: 50%;

}
 .incorrect > .icn {
     width: 300px;
     height: auto;
     margin:20px;
 }


 .share{
     width: 25%;
     margin-top: 20px;
 }

div.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: center;
    height: 100%;
}
.screen_share_example{
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}
div.column > .btn{
    padding: 10px;
}
div.pass > .icn{
    fill: #46be8a;
    align-self: center;
}
