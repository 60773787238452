.sp-heading {
  font-size: 36px;
  color: #6c7884;
  text-align: center;
  margin: 30px 0;
}

div.stepper-container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 50px 0;
}

aside.left, aside.right {
  width: 10%;
}

main {
  width: 80%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  justify-content: space-evenly;
}

aside.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.next-button {
  background-color: rgba(0 , 0, 0, 0);
  color: #6c7884;
}

.next-button:disabled {
  color: rgba(0 , 0, 0, 0);
  background-color: rgba(0 , 0, 0, 0);
}

.next-button:disabled > svg {
  fill: rgba(0 , 0, 0, 0);
}

.next-button > svg {
  max-width: 50px;
  max-height: 50px;
  fill: #0074cc
}

.next-button > p {
  font-weight: 600;
}