.tally__title {
  font-weight: 600;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.flex-container.center {
  justify-content: center;
}

.tally {
  list-style: none;
}

.note + .tally {
  margin-top: 0;
}

.tally > li, .tally section {
  padding: 20px 0 20px 50px;
  position: relative;
}

.tally > li + li, .tally section + li {
  border-top: 1px solid #e4eaec;
}

.tally__status {
  border: 1px solid #e4eaec;
  border-radius: 50%;
  content: '';
  height: 30px;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transition: all 0.125s 0.125s ease;
  width: 30px;
}

.tally__status:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  content: '';
  height: 17px;
  margin-top: -1px;
  opacity: 0;
  position: absolute;
  transform: scale(0.5) translateX(-50%) translateY(-50%);
  transition: all 0.125s 0.25s ease;
  width: 8px;
}

.tally__status.completed {
  background: #46be8a;
  border-color: #46be8a;
}

.tally__status.completed:after {
  opacity: 1;
  transform: translateX(75%) translateY(25%);
}

.tally__status.in-progress {
  animation: loading 1.5s linear infinite forwards;
  border-left-color: #6c7884;
}

@keyframes loading {
  0% {
      transform: rotate(45deg)
  }
  33% {
      transform: rotate(180deg)
  }
  66% {
      transform: rotate(270deg)
  }
  100% {
      transform: rotate(390deg)
  }
}

.icn.icn-times-circle {
  fill: red;
}

.waiting-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.waiting-time p {
  margin: 0;
  text-align: center;
}

.waiting-time--header {
  border-bottom: 1px solid #6c7884;
}

.waiting-time--body {
  font-size: 1.2rem;
}