div.twin-pane-container {
  display: flex;
  padding: 30px;
  min-height: 600px;
}

section.leftPane, section.rightPane {
  width: calc(50% - 0.5px); 
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

section.leftPane {
  border-right: 1px solid #e4eaec;
  padding-right: 30px;
}

section.rightPane {
  padding-left: 30px;
}