.row{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 33%;
    align-items: center;
    justify-content: center;
    vertical-align: center;
}

.fail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    text-align: center;

}
.speedTestContainer{
    width: 50%;
    height: 100%;
}



.container{
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    vertical-align: center;
}
.retry {
    padding: 10px 30px;
    text-align: center;
    vertical-align: center;
}

.container > .icn {
    fill: red;
    height: auto;
}