.img {
  max-width: 300px;
  max-height: 300px;
}

.img-small {
  max-width: 100px;
  max-height: 100px;
}

.img-small.img-small--adjusted {
  max-width: 115px;
}

.icn {
  max-width: 300px;
  max-height: 300px;
  fill: #0074cc;
}

.icn-check-circle {
  fill: #46be8a;
}