.flex-row {
    display: flex;
    flex-direction: row;

    max-width: 100%;
    max-height: 100%;
}

.mac-download{
    max-width: 200px;
    max-height: 50px;
    width: auto;
    height: auto;
}

.mac-security{

    max-width: 100%;
    max-height: 200px;
    width: auto;
    height: auto;
}

.mac-icon{
    margin: 10px;

    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}