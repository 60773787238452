.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.scroll-box {
  max-height: 300px;
  overflow-y: scroll;
}

.session-details {
  justify-content: center;
}

.session-details > h2 {
text-align: center;
border-bottom: 1px solid #e4eaec;
margin-bottom: 20px;
padding-bottom: 10px;
}

.permitted-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.permitted-items > ul {
  padding-left: 20px;
}

.data {
  list-style: none;
  margin: 0;
  padding: 0;
}

.data > strong {
  display: block;
}

.data > li {
  margin-bottom: 1em;
}

.data > .item {
  margin-bottom: 0;
  border-bottom: 1px solid #e4eaec;
}

.data > .item:last-child {
  border-bottom: 0;
}

.item {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

ul.session-details {
  border-bottom: 1px solid #e4eaec;
}

.progress-bar {
  height: 25px;
  width: 80%;
  border-radius: 3px;
  outline: 2px solid black;
  margin-bottom: 20px;
}

.progress-bar > div {
  overflow: hidden;
  background: #0074cc;
  height: 100%;
}

.progress-bar strong {
  text-align: center;
  color: white;
  position: absolute;
}

.progress-bar > .pending {
  width: 25%;
}

.progress-bar > .provisioning {
  width: 50%;
}

.progress-bar > .spun-up {
  width: 75%;
}

.progress-bar > .complete {
  background: #46be8a;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes sp-loading {
  from {left: -50%;}
  to {left: -20%;}
}

.idle-animation {
  animation: sp-loading 1s infinite;
  animation-timing-function: linear;
  position: relative;
  background: linear-gradient(
    75deg,
    #0074cc,
    #0074cc 10%,
    #ADADFF 10%,
    #ADADFF 15%,
    #0074cc 15%,
    #0074cc 25%,
    #ADADFF 25%,
    #ADADFF 30%,
    #0074cc 30%,
    #0074cc 40%,
    #ADADFF 40%,
    #ADADFF 45%,
    #0074cc 45%,
    #0074cc 55%,
    #ADADFF 55%,
    #ADADFF 60%,
    #0074cc 60%,
    #0074cc 70%,
    #ADADFF 70%,
    #ADADFF 75%,
    #0074cc 75%,
    #0074cc
    );
  opacity: 60%;
  width: 200%;
  height: 100%;
}