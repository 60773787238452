.camera-check-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.camera-check-panel canvas {
    background-color: black;
}

.camera-dropdown {
    background: #fff;
    border: 1px solid #e4eaec;
    border-radius: 3px;
    color: #526069;
    font-size: inherit;
    line-height: 1.5;
    padding: 6px 27px 6px 10px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 30px;
}

.hide-me {
    display:none;
}

.webcam__stream {
    transform: scaleX(-1);
}