body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  /* color: #6c7884; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6c7884
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  /* Button Styles */
  /* Normal*/
.btn {
  background: #0074cc;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  padding: 8px 15px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background .25s ease, border .25s ease;
  vertical-align: middle;
  z-index: 0;
}

.btn--right {
  float: right;
}

.btn--negative {
  background: #C02126;
}

.btn:disabled,
.btn[disabled] {
  background: #e4eaec;
  color: #6c7884;
  pointer-events: none;
}

.btn--outline {
  background: none;
  border: 1px solid #0074cc;
  color: #0074cc;
  padding: 7px 14px 5px;
}

.btn-group {
  display: flex;
  flex-flow: row;
  margin: 0 -5px;
}

.btn-group > * {
  margin: 0 5px;
  width: 100%;
}
/* End button styles */

p{
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;

}

h2 {
  font-weight: 600;
  margin: 0;
}

li {
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 10px;
}

.center-text {
  text-align: center;
}

/* Modal Styles */
.modal-container {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-container > .modal-overlay {
  transition: .2s;
  background: black;
  opacity: 10%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-container > .modal-overlay.whiteout {
  background: white;
  opacity: 100%;
}

.modal-header {
  display: block;
  font-weight: 400;
  font-size: 1.25rem;
  padding: 19px 21px 17px;
  margin: -20px -20px 20px;
  color: #ffffff;
  background: #0074cc;
  border-radius: 3px 3px 0 0;
}

.modal-content{
  min-width: 500px;
  max-width: 800px;
  min-height: 100px;
  z-index: 1;
  border-radius: 3px;
  padding: 20px;
  max-height: 95%;
  background: #ffffff;
}

.modal-content.error {
  min-width: 0;
  width: 300px;
}

/* End modal styles */

/* Loader styles */

@keyframes loaderFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loaderMask {
  from {
    background-position: -1000px 0;
  } to {
    background-position: 1000px 0;
  }
}

.loader {
  animation: .25s loaderFade ease;
  background: #fff;
  padding: 15px;
  z-index: 0;
  border-radius: 3px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

@keyframes loader {
  0% {
		transform: rotate(0deg);
	}
	15% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(720deg);
	}
	100% {
		transform: rotate(720deg);
	}
}

.loader__msg {
  color: #526069;
  font-weight: 500;
  overflow: hidden;
}

.loader__msg:before {
  animation: 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) infinite loader;
  border: 10px solid #3a91e4;
  border-right-color: #f9cd48;
  border-bottom-color: #46be8a;
  border-left-color: #f44c87;
  border-radius: 50%;
  content: '';
  display: block;
  margin: 0 auto;
  height: 40px;
  width: 40px;
}

/* End loader styles */


.meta {
  border: 0!important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px!important;
	overflow: hidden!important;
	padding: 0!important;
	position: absolute!important;
	width: 1px!important;
}