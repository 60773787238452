.session-status-error-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.session-status-error_pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 2px solid #6c7884;
    border-radius: 5px;
    padding: 25px;
}

.session-status-error_pane p {
    font-size: 18px;
}

.session-status-error-logo {
    max-height: 234px;
    max-width: 750px;
}
